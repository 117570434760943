<template>
  <div>
    <h2>Erforderliche Deckenstärke / Ausnutzung</h2>
    <p>Zu allen Plattentypen (VMM VSD, VMM-L EPD, VMM-L SCD) wird in der Tabelle die statische Ausnutzung für die verschiedenen Deckenstärken ausgewiesen.
      Den Vorgaben entsprechend wird automatisch die von der Deckenstärke her günstigste Platte gelb hinterlegt. Durch anklicken des entsprechenden Feldes mit der Maus können auch andere Platten ausgewählt werden, wobei Details zum Ergebnis der Ausnutzungs-
      berechnung unten in der Tabelle angezeigt werden.
      Bei einer Vorbemessung empfiehlt sich eine Aus­nutzung von maximal 80%. Ausnutzungen unter 100% werden i.R. grün angezeigt. Bei der Auswahl zu schlanker Platten h/L &lt; 40...50 erscheint die Ausnutzung in rot. Graue Felder können nicht ausgewählt werden (Nicht in der Zulassung bzw. in der Typenprüfung).
      Platten mit dem Vermerk "überschr" können auf Grund nicht ausreichender Tragfähigkeit nicht ausgewählt werden.
      Bei Feldern mit grauer Schrift und allgemien bei der Nutzung von Kragplatten ist die Verfügbarkeit zu klären, da diese Platten nicht zum Standardprogramm gehören.</p>
  </div>
</template>

<script>
export default {
  name: "ResultHelp"
};
</script>

<style lang="scss">
</style>